(function($){

// ---------- Header START
$.widget('tap.header', {

    _create: function(){
        this._initScrollBehavior();
				if($('#nav')) this._initNav();
    },

    _initScrollBehavior: function(){
        var header = $(this.element);

        toggleHeaderClass();
				
				$(window).on('scroll', toggleHeaderClass);

       /* enquire.register('screen and (min-width: 60em)', {
            match: function(){
                $(window).on('scroll', toggleHeaderClass);
            },
            unmatch: function(){
                $(this.element).removeClass('is-full');
                $(window).off('scroll', toggleHeaderClass);
            }
        });*/

        function toggleHeaderClass(){
            if($(document).scrollTop() > 64 && !header.hasClass('is-scrolled')) {
                header.addClass('is-scrolled');
            }
            if($(document).scrollTop() <= 64) {
                header.removeClass('is-scrolled');
            }
        }
    },

    _initNav: function(){
        var toggler = $('.js-nav-toggler');
				var menu_items = $('.nav__menu-item-link');

        toggler.on('click', toggleNav);
				menu_items.on('click', closeNav);				

        function toggleNav(){
					if($('body').hasClass('is-overlayed')) {
							closeNav();
					} else {
							openNav();
					}
        }

        function openNav(){
					$('body').addClass('is-overlayed');
					$(window).on('keyup', listenEscKey);
        }

        function closeNav(){
					$('body').removeClass('is-overlayed');
					$(window).off('keyup', listenEscKey);
        }

        function listenEscKey(e){
          if(e.keyCode === 27) {
            closeNav();
          }
        }
    }

});
// ---------- Header END

})(jQuery);
