(function($){

// ---------- APP START
var App = {

	config: {
		loaderMarkup: '<div class="loader-bubble"></div>',
		scrollOffset: 0,
		relativeTime: {
      perMinute: 60000,
      perHour: 3600000,
      perDay: 86400000,
      perMonth: 2592000000,
      perYear: 946080000000
    },
    timeUnit: {
      seconds: { fr: 'sec', en: 'sec', br : 'seg' },
      minutes: { fr: 'min', en: 'min', br : 'min' },
      hours: { fr: 'h', en: 'h', br : 'h' },
      days: { fr: 'jours', en: 'days', br : 'dias' },
      months: { fr: 'mois', en: 'months', br : 'meses' },
      years: { fr: 'ans', en: 'years', br : 'anos' }
    },
		timePhrase: { fr: 'Il y a %time', en: '%time ago', br: 'Há %time' },
		boat_bg: ['bg_section-boat-left.jpg','bg_section-boat-right.jpg']
	},


	init: function(){

		if(typeof(svg4everybody) !== 'undefined') svg4everybody();

    // Init object-fill polyfill
    if(typeof(objectFitImages) !== 'undefined') objectFitImages();

    $('a[href^="#"]').on('click', function(e){
      var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');
      if(scrollTo !== "#") {
        e.preventDefault();

        if ($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
          App.scroll(scrollTo, $(this).data('scroll-offset'));
      }
    });

    if($('[data-scroll]').length){
      App.scroll($('[data-scroll]').data('scroll'));
    }

		$( window ).resize(function() {
			App.skewIt();
			App.HeightIt();
		});

		if($('.section-boat-wrapper').length) {
			imageUrl = '/public/images/web1/layout/' + App.config.boat_bg[Math.round(Math.random())];
			$('.section-boat-wrapper').css('background-image', 'url(' + imageUrl + ')');
		}

		$('.member-view .page-header__figure').each(function(){
			 App.headerMember.init(this);
		});

		if($('#header')) $('#header').header();
		if($('#home-video')) App.initHomeVideo();
		if($('.js-mfp-map').length) App.mfp.initMapBtn();
		if($('.home-medias-slider').length) App.initMediasCarousel();
		if($('.program-slider').length) App.initProgram();
		if($('.js-mfp-video').length) App.mfp.initSingleVideo();
		if($('.js-mfp-secret').length) App.mfp.initSecretGallery();
		if($('.js-mfp-single-media').length) App.mfp.initSingleMedia();		
		if($('.mfp-single-link').length) App.mfp.initSinglePhoto();
		if($('.boat-keypoints').length) App.initBoatKeypoints();
		if($('.nl-quick').length) App.initQuickNewsletterSubscription();
		if($('#js-supports')) App.initSupportsfeed();
		if($('.member__prizes-slider').length) App.initPrizesCarousel();
		if($('.supports-slider').length) App.initSupportsCarousel();
		if($('.medias-slider').length) App.initPhotosCarousel();
		if($('.supports-form').length) App.initSupportsForm();
		if($('[data-ll-pager]').length) App.initLazyLoadPager();
		if($('.js-mfp-content').length) App.mfp.initVideosPreviews();
		if($('.wvg__nav').length) App.initNavWebDocVG();
		if($('.wvg__scrolldown').length) App.initScrollToVG();
		if($('#js-talk-about')) App.initTalkAboutfeed();
		if($('.vg-live__tabs')) App.initTabsHomeLive();
		if($('.vg-live__infos')) App.initCounts();
		if($('.live__carto')) App.initHomeMap();

		if ($('.countdown').length) {
      $.ajax({
        url: '/' + $('html').attr('lang') + '/get-time',
        success: function(html) {
          $('.countdown').each(function(){
            $(this).countdown({
              timestampStart: parseInt(html),
              timestampEnd: parseInt($(this).attr('data-date')),
              classes: {
                sign: 'chrono-item.m--sign',
                days: 'chrono-item.m--days .countdown__value',
                hours: 'chrono-item.m--hours .countdown__value',
                minutes: 'chrono-item.m--minutes .countdown__value',
                seconds: 'chrono-item.m--seconds .countdown__value'
              },
              mode: $(this).attr('data-mode') !== undefined ? $(this).attr('data-mode') : 'default'
            });
          });
        }
      });
    }
	},

  scroll: function(element, offset){
    offset = offset === undefined ? App.config.scrollOffset : offset;
    $('html, body').animate({ scrollTop: $(element).offset().top - offset}, 'slow');
  },


  load: function(){
    if (window.location.hash && $(window.location.hash).length) App.scroll(window.location.hash, 100);
		App.skewIt();
		App.HeightIt();
  },

  initHomeVideo: function(){
		var player = $('#home-video');
		var videoID = player.data('id');
		player.YTPlayer({
				fitToBackground: true,
        videoId: videoID,
        pauseOnScroll: false,
        playerVars: {
          modestbranding: 0,
          autoplay: 1,
          controls: 0,
          showinfo: 0,
          branding: 0,
          rel: 0,
          autohide: 1,
					playsinline: 1
        }
		});
  },

  initQuickNewsletterSubscription: function(){

		$('.nl-quick').each(function(){
			$(this).on('submit', function(e){
				e.preventDefault();
				var $form = $(this);
				$.ajax({
					type: 'POST',
					data: $form.serialize(),
					url: $form.attr('action'),
					dataType: 'json',
					success: function(data){
						if(data.cleanInput === true)
							$form[0].reset();

						$form.find('.messages').remove();
						var message = $('<div class="messages m--thin"><p class="messages__item m--' + data.status + '">' + data.message + '<svg role="img" class="svg-icon tick"><use xlink:href="/public/images/web1/pictos/misc-76942a7823.sprite.svg#tick"></use></svg></p></div>');
						$form.prepend(message);

						setTimeout(function(){ message.remove(); }, 3500);
					}
				});
			});
		});
  },

  initSupportsForm: function(){

		$('.form-supports').each(function(){
			$(this).on('submit', function(e){
				e.preventDefault();
				var $form = $(this);
				$.ajax({
					type: 'POST',
					data: $form.serialize(),
					url: $form.attr('action'),
					dataType: 'json',
					success: function(data){
						if(data.cleanInput === true)
							$form[0].reset();

						$form.find('.messages').remove();
						var message = $('<div class="messages m--thin"><p class="messages__item m--' + data.status + '">' + data.message + '<svg role="img" class="svg-icon tick"><use xlink:href="/public/images/web1/pictos/misc-76942a7823.sprite.svg#tick"></use></svg></p></div>');
						$form.prepend(message);

						setTimeout(function(){ message.remove(); }, 3500);
					}
				});
			});
		});
  },

	initSupportsfeed: function() {
    if (window.location.hash && window.location.hash === '#formulaire'){
			$("#supports-form").css('display','block');
		}

		$('.js-btn-supports').click(function() {
			$("#supports-form").toggle("350", function() {
				// Animation complete.
			});
		});

		$('#js-supports').masonry({
			itemSelector: '.supports-slide',
			percentPosition: true,
			horizontalOrder: true
		});
	},

	initTalkAboutfeed: function() {

		$('#js-talk-about').masonry({
			itemSelector: '.talk-about-slide',
			percentPosition: true,
			horizontalOrder: true
		});
	},

	setHeaderMember: function() {

	},

	mfp: {
    initSecretGallery: function(){
      $('.js-mfp-secret').magnificPopup({
        delegate: '.js-mfp-secret-item',
        type: 'image',
				closeOnContentClick: false,
        gallery: {
          enabled: true
        }
      });
    },

    initSingleMedia: function(){
      $('.js-mfp-single-media').magnificPopup({
				delegate: '.media-thumb',
        type: 'image'
      });
    },

    initSinglePhoto: function(){
      $('.mfp-single-link').magnificPopup({
        type: 'image'
      });
    },

		initSingleVideo: function(){
      $('.js-mfp-video').magnificPopup({
        type: 'iframe',
      });
    },

		initMfpProgram: function(){
			$('.js-mfp-program').on('click', function () {
				var content = $(this).find('.mfp-program');
				$.magnificPopup.open({
						items: {
								src: content,
						},
						type: 'inline'
				});
			});
    },

    initGallery: function(){
      $('.js-mfp-gallery').magnificPopup({
        delegate: '.media-thumb',
        type: 'image',
				mainClass: 'mfp-img-mobile',
				closeOnContentClick: false,
        gallery: {
          enabled: true,
        },
				callbacks: {
          open: function(){
					 //$('.mfp-container').append('<div class="popup-logo"><img class="svg-icon" src="/public/images/web1/logos/logo-LBL.svg" alt=""></div>');
          }
        }
      });
    },

		initMapBtn: function(){

      $('.js-mfp-map').magnificPopup({
        type: 'iframe',
				callbacks: {
          open: function(){
					 $('.mfp-iframe-holder').addClass('mfp-map');
          }
        }
      });
    },
	},

	initMediasCarousel: function(){
    $('.home-medias-slider').each(function(){
      $(this).find('.carousel__view').slick({
        arrows: true,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
        nextArrow: $(this).find('.carousel__arrow.m--next'),
				dots: false,
        infinite: true,
        mobileFirst: true,
        accessibility: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 640,
						settings: {
							arrows: false,
							slidesToShow: 2,
							slidesToScroll: 2
						}
					}
        ]
			});
		});

		$('.medias-filter').on('click', function(){
			var filter = $(this).data('filter');
			$('.medias-filter, .home-medias-slider').removeClass('is-active');
			$(this).addClass('is-active');
			$(filter).addClass('is-active');
		});
	},

	initProgram: function(){
		var startslide = 0;
		$('.program-slide').each(function(index){
			if($(this).data('race-active') == 1) {
				startslide = index;
				return false;
			}
		});

    $('.program-date-slider').each(function(){
      $(this).find('.carousel__view').slick({
        slide: '.program-slide',
				swipeToSlide: true,
				slidesToShow: 4,
				slidesToScroll: 4,
				centerMode: false,
        arrows: true,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
        nextArrow: $(this).find('.carousel__arrow.m--next'),
        infinite: false,
        mobileFirst: false,
        accessibility: false,
				variableWidth: false,
				initialSlide : startslide,
        responsive: [
					{
						breakpoint: 1450,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
        ]
      });
    });

		App.mfp.initMfpProgram();
  },

	initBoatKeypoints: function(){
		var nslides = $('.keypoints-slide').length;
		var initial = (Math.floor(Math.random()*nslides));
    $('.boat-keypoints').each(function(){
			$(this).addClass('active');
      $(this).find('.carousel__view').slick({
        slide: '.keypoints-slide',
        arrows: true,
				dots: true,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
        nextArrow: $(this).find('.carousel__arrow.m--next'),
        infinite: true,
        mobileFirst: true,
        accessibility: false,
				initialSlide : initial,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
      });
    });
  },

	initPrizesCarousel: function(){
    $('.member__prizes-slider').each(function(){
      $(this).find('.carousel__view').slick({
        slide: '.prizes-slide',
        arrows: true,
				autoplay: false,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
       	nextArrow: $(this).find('.carousel__arrow.m--next'),
				dots: true,
        infinite: true,
        mobileFirst: true,
        accessibility: false,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
      });
    });
  },

	initSupportsCarousel: function(){
		var nslides = $('.support-slide').length;
		var initial = (Math.floor(Math.random()*nslides));
    $('.supports-slider').each(function(){
			$(this).addClass('active');
      $(this).find('.carousel__view').slick({
        slide: '.support-slide',
        arrows: true,
				autoplay: true,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
        nextArrow: $(this).find('.carousel__arrow.m--next'),
        infinite: true,
        mobileFirst: true,
        accessibility: false,
				initialSlide : initial,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
      });
    });
  },

	initPhotosCarousel: function(){
		var nslides = $('.media-slide').length;
		var initial = (Math.floor(Math.random()*nslides));
    $('.medias-slider').each(function(){
			$(this).addClass('active');
      $(this).find('.carousel__view').slick({
        slide: '.media-slide',
        arrows: true,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
        nextArrow: $(this).find('.carousel__arrow.m--next'),
				dots: true,
        infinite: true,
        mobileFirst: true,
        accessibility: false,
				initialSlide : initial,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
      });
    });

		App.mfp.initGallery();
  },

	initLazyLoadPager: function(context){
    $('[data-ll-pager]').each(function(){
      var button = $(this);
      var target = button.data('ll-pager');
      var loader = $(App.config.loaderMarkup);

      if(target){
        var homeContent = $('#' + target);

        button.on('click', function(e){
          e.preventDefault();

          button.hide();
          button.parent().append(loader);

          $.ajax({
            url: button.attr('href'),
            success: function(html){
              var content = $(html);
              var newContent = content.find('#' + target).contents();
              var newBtn = content.find('[data-ll-pager="' + target + '"]');

              homeContent.append(newContent);

              /*switch(button.attr('data-callback')){
                case 'video-index':
                case 'webradio-index':
                  App.initLazyLoad(newContent);
                  break;
                case 'audio-index':
                  App.initAudioPlayers(newContent);
                  break;
                case 'newsfeed':
                case 'skipwords':
                  App.initRelativeTimes(newContent);
                  break;
              }*/

              lazyloadComplete(newBtn);
            },
            error: function(){
              button.show();
              loader.detach();
            }
          });

          function lazyloadComplete(newBtn){
            loader.detach();

            if(newBtn.length){
              button.attr('href', newBtn.attr('href'));
              button.show();
            } else {
              button.parent().remove();
            }
          }
        });
      }
    });
	},

	initNavWebDocVG: function() {
		var topRange = 100,
		contentTop = [];

		var $nav = $('.wvg__nav');
		var $nav_wrapper = $nav.parent();
		if ($nav.length){

			$('.section-wvg').each(function(){
				contentTop.push( $(this).offset().top );
				$nav.append('<li id="nav_'+$(this).attr('id')+'"><button data-scroll="#'+$(this).attr('id')+'"></button></li>');
			});
			var $share = $('.section-wvg .page-share');
			$nav.find('button').on('click', function(){
				App.scroll($($(this).data('scroll')));
			});

			$(window).on('scroll', function () {
				window.requestAnimationFrame(function() {
					var winTop = $(window).scrollTop();
					$.each( contentTop, function(i,loc){
						if ( ( loc < winTop + topRange ) ){
							$('.wvg__nav li')
							.eq(i).addClass('overtook');
						}
						else{
							$('.wvg__nav li')
							.eq(i).removeClass('overtook');
						}
					});
					if ($(window).outerWidth() > 1024){
						// Handling of the navigation
						// "Natural" position
						var natpos = $share.offset().top - winTop + $share.outerHeight() + 20;
						// "Fixed" position
						var fixedpos = $(window).height()/2 - $nav_wrapper.outerHeight()/2;
						$nav_wrapper.css('top', Math.max(natpos,fixedpos)+'px');
					}
					else{
						$nav_wrapper.css('top', 'auto');
					}


				});
			});
			$(window).trigger('scroll');
		}
	},

	initScrollToVG: function(){
		$('.wvg__scrolldown').on('click',function(){
			App.scroll($($(this).data('href')+' .section-wvg__content'),150);
		});
	},

	initTabsHomeLive: function(){
		$('.vg-live__tabs button').on('click', function(){
			$(this).parent().find('.m--open').removeClass('m--open');
			if ($(this).hasClass('vg-live__tab-figures')){
				$('.vg-live__infos').removeClass('m--hidden');
				$('.vg-live__carto').addClass('m--hidden');
				$(window).trigger('tab-infos');
			}
			else{
				$('.vg-live__carto').removeClass('m--hidden');
				$('.vg-live__infos').addClass('m--hidden');
				$(window).trigger('tab-infos');
			}
			$(this).addClass('m--open');
		});
	},

	initHomeMap: function(){
		var $map;
		
		$('.js-btn-tracker').on('click', function(e){
			e.preventDefault();
			$map = $('.live__carto').find('.live__carto-wrapper');			
			$map.html('<iframe src="' + $(this).attr('href') + '" allowfullscreen></iframe>');			
			$('.live__carto').addClass('m--open');
		});
		
		$('.js-btn-tracker-close').on('click', function() {
			$('.live__carto').removeClass('m--open');
		});
	},

	// Init networks totals
	initCounts: function() {
		$('.infos__value').each(function() {
			var count = $(this),
				decimal = (count.text().indexOf('.') !== -1) ? true : false,
				unit = (count.text().indexOf('%') !== -1) ? count.text().substring(count.text().indexOf('%'), count.text().length) : false,
				to = (unit !== false) ? count.text().substring(0, count.text().indexOf('%')) : count.text(),
				performed = false,
				viewport = {},
				bounds = {};

			bounds.top = count.offset().top;
			bounds.bottom = bounds.top + count.outerHeight();

			performAnimation();

			$(window).on('scroll resize tab-infos', performAnimation);

			function performAnimation(e) {
				viewport.top = $(window).scrollTop();
				viewport.bottom = viewport.top + $(window).height();
				if (e !== undefined && e.type == 'tab-infos'){
					performed = false;
				}
				if (bounds.bottom == 0 && bounds.top == 0){
					// Case where vg-live__infos is not displayed (mobile)
					bounds.top = count.offset().top;
					bounds.bottom = bounds.top + count.outerHeight();
				}
				if (performed === false && bounds.top <= viewport.bottom && bounds.bottom >= viewport.top) {
					count.prop('counter', 0).animate({
						counter: to
					}, {
						duration: 1500,
						step: function(now) {
							var value = (decimal !== false) ? Math.ceil(now*10)/10 : Math.ceil(now);
							if (unit !== false) { value += '' + unit;	}
							count.text(value);
						}
					});
					performed = true;
				}
			}
		});
	},

	skewIt: function () {
    var ext = ["","-webkit-", "-moz-", "-ms-"];
		$(".fit-skew").each(function(el){
			var s = $(this).find('span');
    	var c = $(this).parent();
    	var w = c.width();
    	var h = c.height();
    	var angle = (-0.98 * ((Math.atan(h/w))/(2*Math.PI))*360);
			var skewA = "skewY("+angle+"deg)";
			var skewB = "skewY("+(angle*-1)+"deg)";
			// g.width(Math.sqrt(Math.pow(w,2)+Math.pow(h,2)));
			for(i = 0; i <= ext.length; i++) {
				$(this).css(ext[i]+"transform", skewA);
				s.css(ext[i]+"transform", skewB);
			}
		});
	},

	HeightIt: function () {
		if($(window).width() > 1279) {
			var height_src = $('.news-main-live-visual').height();
			$('.dashboard__wrapper').height(height_src - 59);
		}
		else {
			$('.dashboard__wrapper').height('auto');
		}
	},

	headerMember: {
		markup: {
			obj: null,
			banner: null,
			bannerAlt: null
		},

		init: function (obj) {
			this.markup.obj = $(obj);
			this.markup.banner = this.markup.obj.data('banner-src');
			this.markup.bannerAlt = this.markup.obj.data('banner-alt');
			this.setSrc();
		},

		setSrc: function () {
			var visual = 	$(window).width() > 640 ? this.markup.banner:this.markup.bannerAlt;
			this.markup.obj.css('background-image', 'url(' + visual+ ')');

			enquire.register('screen and (min-width: 64em)', {
				match: function() {
					App.headerMember.markup.obj.css('background-image', 'url(' + App.headerMember.markup.banner + ')');
				},
				unmatch: function() {
					App.headerMember.markup.obj.css('background-image', 'url(' + App.headerMember.markup.bannerAlt + ')');
				}
			});
		},
	},


  Tac: function() {

    window.tarteaucitronForceLanguage = $('html').attr('lang');
    tarteaucitron.init({
      "hashtag": "#tac",
      "highPrivacy": false,
      "orientation": "bottom",
      "adblocker": false,
      "showAlertSmall": false,
      "cookieslist": true,
      "removeCredit": true
    });

    tarteaucitron.user.gtagUa = "UA-134463970-1";
    (tarteaucitron.job = tarteaucitron.job || []).push("gtag");
  },

  gtag: function(action, category, label, value) {
    if(window.gtag !== undefined) {
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
      });
    }
  },

};
// ---------- APP END

// Init app
$(document).ready(App.init);
$(window).on('load', App.load); // jQuery 3
window.App = App;
App.Tac();
})(jQuery);
