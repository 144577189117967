(function(){

// MAGNIFIC POPUP
$.extend(true, $.magnificPopup.defaults, {
  // closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg role="img" class="svg-icon"><use xlink:href="/public/images/web1/pictos/misc.sprite.svg#close"></use></svg></button>',
  // tClose: 'Close (Esc)', // Alt text on close button
  // tLoading: '<div class="spinner-wrapper"><div class="spinner-bubble"></div></div>', // Text that is displayed during loading. Can contain %curr% and %total% keys
  iframe: {
    patterns: {
      youtu: {
        index: 'youtu.be/',
        id: function( url ) {
          // Capture everything after the hostname, excluding possible querystrings.
          var m = url.match( /^.+youtu.be\/([^?]+)/ );
          if ( null !== m ) {
            return m[1];
          }
          return null;
        },
        // Use the captured video ID in an embed URL.
        // Add/remove querystrings as desired.
        src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
      },
      dailymotion: {
        index: 'dailymotion.com/',
        id: function(url){
          var m = url.match(/^.+dailymotion.com\/(embed\/)?(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/);
          if (m !== null) {
            if(m[5] !== undefined) {
              return m[5];
            }
            return m[3];
          }
          return null;
        },
        src: 'https://www.dailymotion.com/embed/video/%id%?wmode=transparent&endscreen-enable=false&ui-highlight=3b75b4&ui-logo=false&ui-start-screen-info=false&autoplay=true'
      },
      dai: {
        index: 'dai.ly/',
        id: function(url){
          // Capture everything after the hostname, excluding possible querystrings.
          var m = url.match( /^.+dai.ly\/([^?]+)/ );
          if ( null !== m ) {
            return m[1];
          }
          return null;
        },
        src: 'https://www.dailymotion.com/embed/video/%id%?wmode=transparent&endscreen-enable=false&ui-highlight=3b75b4&ui-logo=false&ui-start-screen-info=false&autoplay=true'
      }
    }
  },
  gallery: {
    //     tPrev: 'Previous (Left arrow key)', // Alt text on left arrow
    //     tNext: 'Next (Right arrow key)', // Alt text on right arrow
    tCounter: '', // Markup for "1 of 7" counter
    arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"><svg role="img" class="svg-icon mfp-prevent-close"><use xlink:href="/public/images/web1/pictos/misc.sprite.svg#chevron-%dir%" class="mfp-prevent-close"></use></svg></button>',
  },
  image: {
  //     tError: '<a href="%url%">The image</a> could not be loaded.' // Error message when image could not be loaded
    titleSrc: function(item){
      var copyright = item.el.attr('data-copyright');
      var title = item.el.attr('title');

      if(copyright){
        title += '<small>' + copyright + '</small>';
      }

      return title;
    }
  },
  // ajax: {
  //     tError: '<a href="%url%">The content</a> could not be loaded.' // Error message when ajax request failed
  // }
  // removalDelay: 300,
});

})();
